// other import statements for styling etc.

import React from "react";
import { MDXProvider } from "@mdx-js/react";

const components = {
p: props => <p {...props} style={{ color: '#1b1b1b',
fontSize: '18px',
lineHeight: '30px',
fontWeight: '100',
fontFamily: 'Lato, sans-serif' }} />,
h4: props => <h4 {...props} style={{  fontSize: '21px',
fontWeight: 'bold',
margin: '0',
fontStyle: 'italic'
 }} />,
h6: props => <h6 {...props} style={{  fontSize: '15px',
fontWeight: 'bold',
margin: '0',
marginBottom:'20px'
 }} />,
a:  props => <a {...props} style={{color:'black',marginLeft: '30px'}}/>,
  
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};